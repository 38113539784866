button.primary {
    background: var(--ion-color-primary);
    font: var(--app-medium-font);
    font-weight: bold;
    color: white;
    border-radius: 0.25em;
    padding: 0.7em;
    font-size: 1.1em;
    margin-top: 0.25em;
    letter-spacing: 0.06em;

    &:hover {
        background: var(--ion-color-primary-tint);
    }

    &:active {
        background: white;
    }
}
