/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// @import '~@ionic/angular/css/text-transformation.css';
// @import '~@ionic/angular/css/flex-utils.css';

@import './theme/colors.scss';
@import './theme/typography.scss';
@import './theme/forms.scss';
@import './theme/pages.scss';
@import './theme/sass-vars.scss';
@import './theme/alerts.scss';
@import './theme/segment.scss';
@import './theme/item-list.scss';
@import './theme/toggle.scss';
@import './theme/button.scss';
@import './theme/new-forms.scss';

// All global css variables. All variables should be prefixed "--app-"
:root {
    --app-color-border: #dddddd;
    --app-color-background-alt: #f5f5f5;
    --app-bold-font: 'Montserrat SemiBold';
    --app-medium-font: 'Montserrat Regular';

    /* Note: this value should be used for edge-of-screen margins, or in
     * other rare situations where we don't want the margin to be based on
     * the font size. In most situations we want to use an appropriate em
     * value for margins. */
    --app-page-margin: 16px;
    /* The maximum width to set for any page content. This helps pages look
     * better when viewed on devices with wide screens (eg. tablet, desktop)
     * Note: ignored for ios devices. (see #184872291) */
    --app-content-max-width: 600px;
    /* The vertical space between an input box, or things that look like an
     * input box, and the stacked label above */
    --app-input-to-label-margin: 0.25em;
    /* The vertical space below an input box (and similar looking things) */
    --app-input-bottom-margin: 1em;
    --app-disabled-opacity: 0.4;
    --app-label-font-size: 0.9rem;
}

// Ionic customizations
:root {
    --ion-background-color: #fff;
    --ion-text-color: #444444;
    --ion-toolbar-border-color: var(--app-color-border);
    --ion-font-family: 'Montserrat Light';
    --ion-item-border-color: var(--app-color-border);
}

// General ion-icon styling
ion-icon {
    // Most icons are primary colour by default
    color: var(--ion-color-primary);
}

// General ion-button styling
ion-button {
    text-transform: unset;
    font-size: 1.1em;
    font-family: var(--app-medium-font);
}

// This block fixes button (vertical) sizing issues
ion-button {
    // Make buttons slightly taller than the ionic default of 36px. Setting
    // a reasonable min height makes (most) buttons the same height, but
    // allows them to expand if needed
    min-height: 46px;
    height: unset;
    //accommodate 200% text magnification
    white-space: normal;
    // The extra padding is needed for browsers that don't support "::part"
    // selectors (eg Safari <= 12) otherwise buttons get vertically "squashed".
    --padding-top: 0.75em;
    --padding-bottom: 0.75em;
    &::part(native) {
        min-height: 46px;
    }
}

/* Have buttons and links focus in the brand color */
a:focus,
button:focus {
    outline-color: var(--ion-color-primary);
}

html {
    color: var(--ion-text-color);
}

// The down-facing chevron on select inputs
ion-select::part(icon) {
    opacity: 1;
    color: var(--ion-color-primary);
}

input[type='number'] {
    -moz-appearance: textfield !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

// svgs are an inline element by default and height is calculated  to include default line-height
// this adds 4px to the element containing it
svg {
    display: block;
}
// fixes svg being cut off
svg:not(:root) {
    overflow: visible;
}
