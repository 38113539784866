@font-face {
    font-family: 'Montserrat Light';
    font-style: light;
    font-weight: 200;
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('../assets/fonts/montserrat/Montserrat-Light.otf')
            format('opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'Montserrat SemiBold';
    font-style: semibold;
    font-weight: 400;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('../assets/fonts/montserrat/Montserrat-SemiBold.otf')
            format('opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'Montserrat Regular';
    font-style: regular;
    font-weight: 300;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../assets/fonts/montserrat/Montserrat-Regular.otf')
            format('opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
