/* General styles for page structure */

// General page title styling
ion-title {
    text-align: center;
    /*
     * TODO - this is a hack to have the title text centred and cut off if it
     * gets too long. (prevents it from potentially overlapping with any
     * toolbar buttons)
     */
    padding-inline-start: 40px;
    padding-inline-end: 40px;
}

/* This block ensures the ion-title is centered when there's header buttons */
ion-header {
    ion-back-button {
        position: absolute;
    }

    ion-buttons[slot='start'] {
        position: absolute;
        left: 0px;
    }

    ion-buttons[slot='end'] {
        position: absolute;
        right: 0px;
    }
}

// General styling of the button action areas that appear on most pages.
// The standard is horizontally arranged buttons, and the stacked version
// has them vertically arranged.
section.page-action-area {
    justify-content: center;
    /* This is a hack to address the lack of gap support across browsers. This
     * half page margin combines with the ion-button half page margins to make
     * up a full margin. A similar thing happens with the gaps between adjacient
     * buttons. */
    margin: 0 calc(var(--app-page-margin) / 2);
    margin-top: calc(var(--app-page-margin) / 2);

    ion-button {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        margin-inline-start: calc(var(--app-page-margin) / 2);
        margin-inline-end: calc(var(--app-page-margin) / 2);
        --padding-start: 0;
        --padding-end: 0;
    }
}

section.stacked-page-action-area {
    flex-direction: column;
    align-items: center;
    margin: 0 var(--app-page-margin);

    ion-button {
        width: 100%;
    }
}

section.page-action-area,
section.stacked-page-action-area {
    display: flex;

    ion-button {
        font-family: var(--app-bold-font);
        // Allows a single button to take up the full screen width on most
        // mobile devices, but not have it go too wide on eg. tablets.
        max-width: 400px;
        margin-bottom: calc(var(--app-page-margin) / 2);
    }
}

/*
 * This block sets a max width for most pages in the app. That prevents the
 * pages from looking too awkward when stretched out on wide displays, and
 * follows the general look/format of the legacy app.
 *
 * As of #184872291 apple devices will ignore this behavior.
 *
 * Wrapped in a media query because otherwise it prevents input auto-scrolling
 * in chrome/android for some reason.
 */
ion-app:not(.ios) ion-content.page-content {
    /* Selects the "main" element that gets populated into ion-content */
    &::part(scroll) {
        // Don't mess with bottom padding (see https://www.pivotaltracker.com/story/show/185667493/comments/237807494)
        padding-left: calc(calc(100% - var(--app-content-max-width)) / 2);
        padding-right: calc(calc(100% - var(--app-content-max-width)) / 2);
    }
}

/* This adds a focus indicator to the currently selected segment button */
ion-segment-button {
    ion-label {
        border-bottom: solid 2px transparent;
        white-space: normal !important;
    }

    &.ion-focused {
        ion-label {
            border-color: var(--ion-color-primary);
        }
    }
}

/* This class is used to visually hide elements on the page but they are still
 * accessible to screen readers for accesibility purposes.
 *
 * Note: the element with this class needs to be contained in a relatively
 * positioned element. Otherwise when the page scrolls this will appear in
 * the wrong place. (if the screen reader gives a visual focus)
 */
.screen-reader-only {
    display: inline-block;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    pointer-events: none;
    font-size: 1px;
    /* Makes the content not copyable. Presumably there will be another piece of
     * content that's the non-screen reader version of this which gets copied
     * instead. */
    user-select: none;
    /* This nowrap improves the look of the visual indicator in iOS VoiceOver.
     * Without it, long SRO sentences get wrapped at the 1px width. Even with
     * a 1px font size it turns the indicator into a tall vertical box, which
     * just looks wrong and also causes extra page scrolling. By setting nowrap
     * it forces the indicator into a more horizontal shape. */
    white-space: nowrap;
}

/*
 * This class is used for filter selectors that scrub back and forth and display
 * the filtered value in the middle
 * IE...     < 2022 >
 */
.date-selection {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ion-button::part(native) {
        min-height: unset;
        padding-inline-start: unset;
        padding-inline-end: unset;
    }

    ion-button {
        width: 2em;
        --background: none;
        --box-shadow: none;
        min-height: unset;

        &.ion-focused {
            outline: 2px solid var(--ion-color-primary);
        }
    }

    ion-button:disabled {
        ion-icon {
            opacity: 0.25;
        }
    }

    .current {
        margin: unset;
        font-size: larger;
        min-width: 3em;
        text-align: center;
    }

    ion-icon {
        height: 1.5rem;
        width: 1.5rem;
        color: var(--ion-color-dark);
    }
}

/*
 * Styles the google maps info-window popup (local pros page)
 */
app-local-pros-map .gm-style-iw {
    .wrapper {
        padding-right: 1em;
        padding-bottom: 1em;
    }

    .title {
        color: black;
        font-size: larger;
        margin-bottom: 0.5em;
    }

    a {
        display: block;
    }
}

/*
 * General styling of page header buttons.
 */
ion-header {
    ion-button {
        color: var(--ion-color-primary);
        font-size: inherit;
        font-family: var(--ion-font-family);
    }

    ion-back-button {
        color: var(--ion-color-primary);
        font-size: inherit;
        /*
         * Ionic upper-cases back button text by default which runs counter to the
         * standard iOS styling. Typically on Android the back button has no text so
         * uppercase/lowercase doesn't matter. (though our app does, so let's stay
         * consistent with iOS)
         * See: https://developer.apple.com/design/human-interface-guidelines/components/presentation/sheets
         */
        text-transform: none;

        /* Reduces the gap between the back button and it's text. It still looks
         * good this way and gives more space for the page title. */
        &::part(text) {
            padding-inline-start: 0px;
        }

        /* The back button (usually) takes up too much space when it is also
         * showing an icon (eg. chevron) and risks running into the centered
         * title. Taking off the start padding addresses this and also looks
         * good. */
        &:not([icon='']) {
            --padding-start: 0;
        }
    }
}

ion-title h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
}

/*
 * The page headers get really crowded on small devices
 */
@media only screen and (max-width: 380px) {
    ion-header {
        ion-button {
            font-size: 0.9rem;
        }

        ion-back-button {
            font-size: 0.9rem;
        }
    }

    ion-title h1 {
        font-size: 1.1rem;
    }
}
