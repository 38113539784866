/* Form styling */

/*
 * The .custom-input class is used to style a non-input element to look like
 * an input element.
 * div wrapper around input - adds a gray border, turns the border brand-colored, when focused, etc)
 * This should be used for elements such as buttons, divs, labels etc
 * when we need to look like other form input elements.
 * If ionic disable control machinery doesnt work with element add [class.disabled]="disabled"
 * to all custom-input elements to manage disabled state styling.
 * Labels also need to be tagged wth custom-input to benefit from the disabled stlying
 */

// General form label/input styling
form {
    ion-item.compact-input {
        // TODO - this selector isn't great (alternatives?)
        ion-label.sc-ion-label-md-h,
        ion-label.sc-ion-label-ios-h {
            font-size: smaller;

            font-family: var(--app-bold-font);
        }

        ion-label {
            text-transform: uppercase;
        }

        ion-label + ion-input {
            text-align: right;
            // This gives us a little more horizontal space for input
            margin-left: 0;
            --padding-start: 0;
            --padding-end: 0;
        }
    }
}

/* Fixes a bug (?) in safari where ion-label gets lots of padding on page load,
 * but the padding disappears when the page is then reloaded. */
ion-label {
    margin: 0;
}

.stacked-input {
    //remove borders on ion-items for boxed input
    //hide focus highlight
    --border-width: 0;
    --inner-border-width: 0px;
    --highlight-height: 0px;

    // General ion-label styling
    // TODO - this selector isn't great (alternatives?)
    ion-label.sc-ion-label-md-h,
    ion-label.sc-ion-label-ios-h {
        width: 100%;
        word-wrap: normal;
        overflow: unset;
        white-space: normal;
        transform: none; // otherwise ionic scales down the label 75%
        font-size: var(--app-label-font-size);
    }

    // General ion-input styling
    ion-input.sc-ion-input-md-h,
    ion-input.sc-ion-input-ios-h {
        --padding-start: 0.5rem;
        --padding-end: 0.5rem;

        &.ion-invalid.ion-untouched {
            color: unset;
        }

        &.ion-invalid {
            color: var(--ion-color-danger);
        }
    }

    // This block puts a border around input fields (and input wrappers)
    ion-input,
    ion-select,
    ion-textarea,
    .icon-input,
    .custom-input {
        border: solid 1px var(--ion-color-medium);
        border-radius: 4px;
        margin: var(--app-input-to-label-margin) 0
            var(--app-input-bottom-margin) 0;
        box-sizing: border-box;
    }

    // This block styles disabled input elements
    &.item-interactive-disabled {
        ion-input,
        ion-textarea,
        .icon-input {
            opacity: var(--app-disabled-opacity);
        }
    }

    // This block styles the sliding toggle when disabled
    .sliding-toggle:disabled {
        opacity: var(--app-disabled-opacity);

        & + label {
            opacity: var(--app-disabled-opacity);
        }
    }

    // This block styles disabled custom input elements
    .custom-input {
        &.disabled {
            opacity: var(--app-disabled-opacity);
        }
    }

    // This block styles disabled labels for custom input elements
    label:has(+ .custom-input.disabled) {
        opacity: var(--app-disabled-opacity);
    }

    ion-select {
        --padding-start: 0.5rem;
        --padding-end: 0.5rem;
    }

    // This block creates thickened border on (non-disabled) focused inputs
    &:not(.item-interactive-disabled) .icon-input,
    &:not(.item-interactive-disabled) .custom-input,
    ion-input:not(:disabled),
    ion-textarea:not(:disabled),
    ion-select:not(:disabled) {
        position: relative;

        &:focus-within::before {
            content: ' ';
            position: absolute;
            display: block;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: 2px solid var(--ion-color-primary);
            border-radius: 4px;
            pointer-events: none; // otherwise this element can block user input
        }
    }

    /* Note that .icon-input contains a button, and when that button is focused
     * we don't want the top-level .icon-input to look focused. This is
     * separated from above for browsers that don't support :has */
    &:not(.item-interactive-disabled) .icon-input:has(button:focus) {
        &::before {
            border: none;
        }
    }

    .icon-input {
        display: flex;
        width: 100%;
        align-items: center;

        ion-input {
            --padding-start: 0.5rem;
            margin: 0;
            border: none;
        }

        ion-input:focus-within::before {
            border: none;
        }

        ion-icon {
            width: 1.7rem;
            height: 1.7rem;
        }

        //remove background colouring to match input background
        // margin trick to account for the border width when focus indicitor adds 2px border

        button {
            outline: none;
            background: none;
            margin-right: 0.5em;
            padding: 0px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button:focus::after {
            content: '';
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            border: 2px solid var(--ion-color-primary);
            border-radius: 4px;
        }
    }

    // chrome fix due to constructed style sheet being applied to select-icon
    // move chevron to center of border box instead of clickable input
    ion-select::part(icon) {
        transform: none;
    }

    form {
        .terms-links > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }
    }
}

// This block prevents the label text from getting cut off in our forms
form {
    ion-item {
        ion-label {
            flex-shrink: 1;
            flex-basis: auto;
            overflow: visible;
            margin-right: 0;
        }

        ion-input,
        ion-select {
            max-width: unset;
            flex-shrink: 1;
            flex-basis: auto;
            white-space: normal;
        }
    }
}

/*
 * In ionic by default an input is marked invalid (and red underlined) when
 * the user focuses it. It doesn't make sense to inform the user there's
 * something wrong when they're about to do what they're supposed to do.
 *
 * What should happen on an eg. required field:
 *
 * 1. Form is rendered: input no highlight
 * 2. Input is focused: input has a success highlight
 * 3. Input is blurred (and validation fails): input has an error highlight
 *
 * This CSS block is to fix this problem.
 */
ion-item:not(.stacked-input) {
    // Ionic considers an input "untouched" until the user blurs - so we can
    // select on that here to fix this bad behaviour.
    &.ion-invalid.ion-untouched {
        --highlight-background: none;
    }

    // Adds a green highlight to focused input
    &.ion-invalid.ion-untouched.item-has-focus {
        --highlight-background: var(--ion-color-success);
    }
}

// The down-facing chevron on select inputs
ion-select::part(icon) {
    opacity: 1;
    color: var(--ion-color-primary);
}

/* Disable background "greying" when an item has input focus, or the user
 * hovers over it. */
ion-item {
    --background-focused: none;
    --background-hover: none;
}

/* Adds an outline to ion-toggle when focused. (missing by default) */
form ion-toggle {
    margin-left: 3px; // Otherwise the focus indicator gets cut off
    margin-right: 3px; // Otherwise the focus indicator runs into the label
    padding: 0.7em;

    &:focus-within {
        outline: solid 2px var(--ion-color-primary);
    }
}

/* Controls the horizontal size of the toggle */
form ion-toggle {
    min-width: 2em; // Otherwise the toggle gets squashed down
    max-width: 2.5em; // Otherwise the toggle adds extra space
}

/*
 * This styles the grayed-out right-facing chevron in ion-items. By default
 * ionic uses "color" for this type of icon, then sets an opacity to gray it
 * out a bit. Since our default html "color" is dark gray (not black) this
 * makes the detail icon too light. So we correct it here by setting it black.
 */
ion-item {
    --detail-icon-color: black;
}

/*
 * This block styles inline validation errors in forms.
 */
.validation-error {
    display: block;
    color: var(--ion-color-danger);
    // The negative margin "pulls" this element into the bottom margin of the
    // above ion-input. This helps keep the page from jumping too much when
    // the error pops into view.
    margin: -1.25em 0.5em 0.5em 0.5em;
    line-height: 1.25;
    font-size: smaller;
}

/*
 * This block styles html select tag and it's label in forms.
 */

.custom-select {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    width: 100%;

    .invalid {
        color: var(--ion-color-danger);
    }

    select:focus {
        outline: solid 1px var(--ion-color-primary);
        border: solid 1px var(--ion-color-primary);
    }

    label {
        margin-bottom: 5px;
        font-size: 0.9em;
    }

    select {
        padding: 0.25em 0.5em;
        height: 2.4em;
        border: 1px solid var(--ion-color-medium);
        border-radius: 5px;
        background-color: var(--ion-color-primary-contrast);
        -webkit-appearance: none; // removes glossy background in safari
    }

    &:focus-within label {
        color: var(--ion-color-primary);
    }

    label:has(+ select:disabled) {
        opacity: var(--app-disabled-opacity);
    }

    select:disabled {
        opacity: var(--app-disabled-opacity);
    }
}
