/* This block styles "new forms" which don't use any ion form elements, but
 * instead use plain HTML. */
.new-form {
    /* General styling for brand-color radio buttons */
    input[type='radio'] {
        appearance: none;
        position: relative;
        width: 0.75em;
        height: 0.75em;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            outline: solid 1px var(--ion-text-color);
        }

        &:checked::before {
            outline: solid 1px var(--ion-color-primary);
        }

        &:checked::after {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 50%;
            border-radius: 100%;
            background: var(--ion-color-primary);
        }
    }

    /* This block puts a border around unfocused input elements */
    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea,
    .custom-input {
        display: block;
        width: 100%;
        padding: 0.5em;
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--ion-color-medium);
        border-radius: 4px;
        border: none;
        background: none;
    }

    label {
        display: block;
        font-size: var(--app-label-font-size);
        margin-bottom: 0.25em;
    }

    // This block styles elements when focused
    textarea:focus,
    input[type='radio']:focus,
    input[type='text']:focus {
        outline-width: 2px;
        outline-color: var(--ion-color-primary);
    }

    // This also styles elements when focused, but broken out for browser that
    // don't support :has
    .custom-input:focus-within {
        outline-width: 2px;
        outline-color: var(--ion-color-primary);
    }

    label:has(+ :focus),
    label:has(+ .custom-input:focus-within) {
        color: var(--ion-color-primary);
    }

    // This block sytles elements when disabled
    textarea:disabled,
    input:disabled {
        opacity: var(--app-disabled-opacity);
    }

    // This block styles labels when disabled
    label:has(+ :disabled),
    label:has(:disabled) {
        opacity: var(--app-disabled-opacity);
    }
}
