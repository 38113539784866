ion-segment {
    background: rgba(var(--ion-color-primary-rgb), 0.15);
    color: black;
    width: unset;

    ion-segment-button {
        --padding-top: 0.5em;
        --padding-bottom: 0.5em;
        font-size: 1rem;
    }
}
