/* Styles for popups and alerts */

.email-updates-help-alert,
.database-attribution-help-alert,
.access-code-help-alert {
    --width: 80%;
    --max-width: 50em;

    p {
        color: var(--ion-text-color);
    }

    .alert-message {
        max-height: 80%;
    }
}

.skip-alert {
    .alert-message {
        text-align: center;
    }

    .alert-head {
        text-align: center;
    }

    .alert-button-group {
        button {
            margin-bottom: 1em;
        }
    }

    .alert-button {
        border: solid 1px var(--ion-color-primary);
        border-radius: 5px;
        text-align: center;
        text-transform: unset;
    }
}

.duration-popover {
    --width: 300px;
}

.try-again-popover {
    --width: 80%;
    --max-width: 25em;
}

.error-toast {
    --color: white;
    --background: #333;
    font-size: larger;

    &::part(message) {
        // Have the toast standout a bit more by taking up more screen space
        padding: 0.5em 0;
    }
}

.success-toast {
    --color: white;
    --background: var(--ion-color-primary);
    font-size: larger;

    &::part(message) {
        // Have the toast standout a bit more by taking up more screen space
        padding: 0.5em 0;
        text-align: center;
    }
}

.talk-test-popover {
    --width: 95%;
    --max-width: 50em;
    --backdrop-opacity: 0.4;

    button {
        // Keeps the focus indicator from getting cut off:
        margin-right: 2px;
    }
}

/* Used to style the terms and conditions popover, and the privacy policy too */
.terms-popover {
    --width: calc(100% - calc(2 * var(--app-page-margin)));
    --height: 90%; // note if too tall it overlaps with ios date/battery area
    --max-width: 50em;
    --max-height: 50em; // overrides the ionic default
    --backdrop-opacity: 0.4;

    app-terms-and-conditions,
    app-privacy-policy {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    ion-header {
        display: flex;
        align-items: center;
        min-height: 4em; // otherwise it gets squashed in old safari (<= v12)
        padding: 0.5em;

        h1 {
            font-size: x-large;
            flex-grow: 1;
            flex-shrink: 0; // otherwise it shrinks on iOS/safari
            margin-left: var(--app-page-margin);
            margin-top: 0;
            margin-bottom: 0;
            color: black;
        }

        .fab-close {
            flex-grow: 0;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            font-size: 2.25em;
            background: none;
            border: none;
            padding: 0;
            margin: 0;
        }
    }

    main {
        padding: var(--app-page-margin);
        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        h2 {
            font-size: large;
            color: black;
        }

        address {
            font-style: normal;
            margin-left: 0.5em;
            margin-top: 1em;
            margin-bottom: 1em;

            div:first-of-type {
                font-style: italic;
            }
        }

        p:first-of-type {
            margin-top: 0;
        }

        .page-action-area {
            margin-top: 2em;

            ion-button {
                max-width: 200px;
            }
        }
    }
}

.notifications-opt-in-popover {
    --width: 90%;
    --max-width: 400px;
    --backdrop-opacity: 0.4;
}

.pro-registration-popover {
    --width: 90%;
    --max-width: var(--app-content-max-width);
    --backdrop-opacity: 0.4;

    app-pro-registration {
        padding-top: 1em;
        padding-bottom: 1em;
    }
}

.complete-status-help-text-popover {
    --width: 90%;
    --max-width: var(--app-content-max-width);
    --backdrop-opacity: 0.4;
}

.accept-terms-popover {
    --width: 75%;
    --max-width: 400px;
}

/* Popover sizing and centering seems to be broken in iOS Safari. It gets
 * shifted off center for larger width values. (tested on iPod Touch 7th gen)
 * So we just fake it here with an almost 100% sizing which looks reasonable. */
.ios {
    .terms-popover,
    .notifications-opt-in-popover,
    .talk-test-popover,
    .complete-status-help-text-popover {
        --width: 97%;
    }
}
