.sliding-toggle {
    --width: 2em;
    --height: 0.75em;
    position: relative;
    display: inline-block;
    appearance: none;
    --webkit-appearance: none;
    background: gray;
    width: var(--width);
    height: var(--height);
    border-radius: calc(var(--height) / 2);
}

.sliding-toggle:checked {
    background: rgba(var(--ion-color-primary-rgb), 0.5);
}

.sliding-toggle::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    box-shadow: 0px 1px 2px gray;
    height: 100%;
    aspect-ratio: 1;
    transform: scale(1.5);
    border-radius: 100%;
    transition: left 100ms;
}

.sliding-toggle:checked::after {
    background: var(--ion-color-primary);
    left: calc(100% - var(--height));
}

.sliding-toggle:focus-visible {
    outline: solid 2px var(--ion-color-primary);
    outline-offset: 0.5em;
}
