/* Usage:

<!-- The list is rendered without bullets or numbers, but (some) screen readers
  -- announce it as a list to give the user more context. -->
<ul class="item-list">
    <li>
        <!-- This will expand to fill the space -->
        <div class="item-content">
            This is the content
        </div>
    </li>

    <!-- The child elements of li are vertically centered -->
    <li>
        <!-- This shrinks down -->
        <div>
            <img src="test.png">
        </div>
        <!-- This expands to fill space -->
        <div class="item-content">
            This is the content
        </div>
    </li>
</ul>

*/

.item-list {
    list-style: none;
    margin: 0;
    margin-left: 1em;
    padding: 0;
}

.item-list li {
    display: flex;
    align-items: center;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-right: 0.5em;

    &:not(:last-of-type) {
        border-bottom: solid 1px lightgray;
    }
}

.item-list li > .item-content {
    flex-grow: 1;
    flex-shrink: 1;
}
